.container {
  display: flex;
  align-items: center;
  margin: 4px 0;
}
.content {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
.ped_icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 24px;
  opacity: 0.6;
}
.ped_icon[data-theme="light"] {
  opacity: 1;
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.ped_text {
  margin: 0 16px 0 8px;
  opacity: 0.6;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.ped_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.gradient_line_wrapper {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.line {
  width: 100%;
  border-style: solid;
  border-radius: 20px;
  border-image-slice: 1;
  border-width: 1px;
  /* border-image-source: linear-gradient(
    90deg,
    #5504d9 16%,
    #262dd4 56%,
    #03acb2 99%
  ); */
}
.text {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  opacity: 0.6;

  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.chart_title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.chart_wrapper {
  min-width: 192px;
  max-height: 300px;
  overflow-y: scroll;
}
.range_wrapper {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  margin-top: 10px;
}
