.container *::-webkit-scrollbar {
  display: none;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.options_wrapper {
  display: flex;
}
.buttons_wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}
.buttons_wrapper span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 8px;
  border-radius: 5px;
  backdrop-filter: blur(31px) saturate(150%);
  -webkit-backdrop-filter: blur(31px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.buttons_wrapper[data-theme="light"] span {
  background-color: var(--button-bg-color-primary-light);
}
.inbound_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.outbound_button[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.inbound_button[data-active="true"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.1);
}
.outbound_button[data-active="true"][data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown_wrapper {
  width: 200px;
}

.comp_options {
  width: 80px;
  height: 32px;
  padding: 4px 4px 4px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.date_options {
  width: 93px;
  height: 32px;
  padding: 4px 4px 4px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.custom_table_row {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  color: #fff;
}
.card_header_range_and_select_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_header_range {
  width: 410px;
}
.card_header_select_options {
  width: 90px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
  margin: 0 0 0 18px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: #4a4266;
  cursor: pointer;
}
.card_header_select_options[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.select_options_selected_item {
  margin-left: 8px;
  font-family: var(--font-family-medium);
}
.select_options_icon {
  width: 24px;
  height: 24px;
}
.card_header_select_options span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.open_menu {
  width: 90px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  position: absolute;
  right: 0;
  top: 40px;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_item {
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}
.open_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.open_menu_item[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_range_and_select_options .icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(-360deg);
}
.metrics_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 12px;
  gap: 8px;
}
.gradient_line_wrapper {
  width: 256px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;
}
.legend_title {
  height: 15px;
  margin: 16px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.legend_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.line {
  width: 100%;
  border-style: solid;
  border-radius: 20px;
  border-image-slice: 1;
  border-width: 1px;
}
.text {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  opacity: 0.6;
  font-family: var(--font-family-light);

  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
