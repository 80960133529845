.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  margin-bottom: 12px;
  position: relative;
  z-index: 5;
}
.switch_date_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.switch_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.switch_button {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
}
.switch_button[data-active="true"] {
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.disabled{
  cursor: no-drop;
}
.date_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
.date_prev_button,
.date_next_button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px 0 0 12px;
  cursor: pointer;
}
.date_next_button {
  border-radius: 0 12px 12px 0;
}
.date_year_text {
  font-size: 20px;
}
.select_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  border-bottom: solid 1px var(--border-color);
  padding-bottom: 1em;
  position: relative;
}
.select_wrapper[data-active="false"] {
  display: none;
}
.select_button {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  color: var(--text-color-secondary);
}
.select_button[data-active="true"] {
  color: var(--text-color-primary);
}

.active_button {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.body {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.calendar_wrapper {
  position: relative;
}
.date_picker_modal {
  min-width: 300px;
  position: absolute;
  background-color: #383051;
  border-radius: 10px;
  z-index: 2;
}
.date_picker_modal[data-active="false"] {
  display: none;
}
.calendar_icon {
  cursor: pointer;
}
