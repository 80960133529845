.container {
  display: flex;
  gap: 8px;
  min-height: 524px;
}
@media only screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
  }
}
