.container {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 15px 15px 50px 15px;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.container[data-theme="light"] {
  background-color: rgba(255, 255, 255, 0.45);
}
.title {
  width: 181px;
  height: 38px;
  margin-left: 28px;
  font-family: var(--font-family-light);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-secondary);
}

.value {
  width: 159px;
  height: 38px;
  margin-left: 36px;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.icon_wrapper {
  width: 64px !important;
  height: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #da7706;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
