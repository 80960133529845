.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.card_header_range_and_select_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_header_range {
  width: 410px;
}
.card_header_select_options {
  width: 90px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 4px 4px 4px 20px;
  margin: 0 0 0 32px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: #4a4266;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.card_header_select_options[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_select_options span {
  font-family: var(--font-family-medium);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_header_select_options[data-theme="light"] span {
  color: var(--text-color-primary-light);
}
.open_menu {
  width: 90px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_item {
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
}
.open_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.open_menu_item[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_range_and_select_options .icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(-360deg);
}
.card_body {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 8px;
}
.image_and_card_wrapper {
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.world {
  width: 100%;
  top: -20px;
}
.word_image_wrapper {
  position: relative;
  overflow: hidden;
}
.word_image_wrapper .comp1 {
  position: absolute;
  z-index: 2;
  left: 260px;
  top: 170px;
}
.word_image_wrapper .comp2 {
  position: absolute;
  z-index: 2;
  left: 100px;
  top: 100px;
}
.word_image_wrapper .host1 {
  position: absolute;
  z-index: 2;
  left: 230px;
  top: 150px;
}
.word_image_wrapper .host2 {
  position: absolute;
  z-index: 2;
  left: 130px;
  top: 240px;
}
.word_image_wrapper .orig {
  position: absolute;
  z-index: 2;
  left: 230px;
  top: 90px;
}
.word_image_wrapper .dest {
  position: absolute;
  z-index: 2;
  left: 265px;
  top: 105px;
}

.mini_card_wrapper {
  display: flex;
  justify-content: space-between;
  z-index: -1;
  gap: 8px;
}

.custom_table_row {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  color: #fff;
}
