.custom_table_row {
  width: 32px;
  height: 8px;
  display: flex;
  align-items: center;
  border-radius: 4.5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.row {
  height: 8px;
  display: inline-block;
  border-radius: 4.5px;
  background-color: #9c00ed;
}
.row[data-active="blue"] {
  background-color: #144aff;
}
.card_title {
  height: 24px;
  margin: 28px 18px;
  
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
