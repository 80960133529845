.accordion {
  border-radius: 10px !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0 !important;
  color: #fff;
  margin: 4px 0;
}
.accordion[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.icon {
  width: 24px;
  height: 24px;
  color: #fff !important;
}
.icon[data-theme="light"] {
  color: var(--text-color-primary-light) !important;
}
.summary_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 8px;
}
.summary_text[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.accordion_details {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 24px !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.accordion_details[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.accordion_details:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.modal_container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.modal_container[data-theme="light"] {
  background-image: url(../../../assets/bg.webp);
}
.modal_buttons_wrapper {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.back_button {
  width: 84px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ec4252;
  cursor: pointer;
}
.back_button_arrow {
  border: solid #ec4252;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  margin-left: 8px;
  margin-right: 8px;
}
.download_button {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
}
.content {
  width: 90%;
  margin-top: 12px;
}
