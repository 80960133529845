.container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.charts_wrapper {
  padding: 0 22px;
}
.chart_title {
  width: 150px;
  height: 11px;
  margin: 0 0 10px 5px;

  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.range_and_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 18px;
  padding-left: 24px;
}
.button_wrapper {
  width: 190px;
  height: 36px;
  margin-left: 32px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.button_wrapper span {
  width: 93px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-color-secondary);
  cursor: pointer;
}
.button_wrapper[data-theme="light"] span {
  color: var(--text-color-secondary-light);
}
.button_wrapper span[data-active="true"] {
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color-primary);
}
.button_wrapper span[data-active="true"][data-theme="light"] {
  background-color: var(--bg-color-white);
}
