.container {
  width: 100%;
}

.chart_and_metrics_wrapper {
  height: 500px;
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
.chart_container {
  width: calc(100% - 235px);
}
.chart_wrapper {
  position: relative;
}
.range_slider_wrapper {
  padding: 0 28px;
}
.detail {
  width: 219px;
  height: 448px;
  padding: 36px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.detail_card {
  height: 40px;
  display: flex;
  align-items: center;
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-right: 12px;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.icon_wrapper[data-active="cyan"] {
  background-color: #259eff;
}
.icon_wrapper[data-active="purple"] {
  background-color: #9c00ed;
}
.icon_wrapper[data-active="blue"] {
  background-color: #144aff;
}
.icon_wrapper[data-active="red"] {
  background-color: #ec4252;
}
.card_title_text {
  width: 128px;
  height: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.text_and_graph_wrapper {
  display: flex;
}
.text_and_graph_wrapper span {
  width: 62px;
  height: 24px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.mini_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;
  z-index: 6;
}
.chart_info {
  width: 144px;
  height: 52px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  font-family: var(--font-family-light);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.chart_info[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.top_left {
  top: 30px;
  left: 0;
}
.top_right {
  top: 30px;
  right: 0;
}
.bottom_left {
  bottom: 0;
  left: 0;
  margin-bottom: 12px;
}
.bottom_right {
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
}
