.container {
  height: 624px;
}

.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 0 28px 16px;
}
.title_button_wrapper {
  width: 190px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.title_button_wrapper[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.title_button_wrapper span {
  width: 94px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: transparent;
  border-radius: 24px;
  cursor: pointer;
}
.title_button_wrapper span[data-active="true"] {
  background-color: rgba(255, 255, 255, 0.1);
}
.title_button_wrapper span[data-active="true"][data-theme="light"] {
  background-color: #fff;
}
.chart_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart_content {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}
.chart {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
.chart_title_card_wrapper {
  display: flex;
  /* position: absolute; */
  /* top: 8px; */
  z-index: 1;
  width: 184px;
  height: 56px;
  /* margin: 0 20px 45px 0; */
  padding: 8px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
}
.chart_title_card_wrapper .top_title {
  max-width: 140px;
  height: 14px;
  margin: 0 0 1px 8px;
  font-family: var(--font-family-light);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.chart_title_card_wrapper .top_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.chart_title_card_wrapper .content_text {
  max-width: 130px;
  height: 24px;
  margin: 1px 0 1px 8px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.table_wrapper {
  margin-top: 8px;
}
.custom_table_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom_table_row .row {
  max-width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.custom_table_row .border {
  display: inline-block;
  width: 8px;
  height: 47.5px;
  border-radius: 0 4px 4px 0;
  position: absolute;
  right: 0px;
}
.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 16px;
  margin: 0 8px 0 0;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.icon_wrapper[data-active="purple"] {
  background-color: #5504d9;
}
.icon_wrapper[data-active="green"] {
  background-color: #00ad92;
}
.icon_wrapper[data-active="red"] {
  background-color: #ec4252;
}
.icon_wrapper[data-active="light_purple"] {
  background-color: #9c00ed;
}
.icon_wrapper[data-active="pink"] {
  background-color: #d77cbb;
}
.icon_wrapper[data-active="yellow"] {
  background-color: #da7706;
}
.icon_wrapper[data-active="blue"] {
  background-color: #144aff;
}
.icon_wrapper[data-active="light_blue"] {
  background-color: #259eff;
}
