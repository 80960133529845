.container {
  background-image: linear-gradient(to right, transparent 1px, transparent 1px),
    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 20px 30px;
  overflow-x: auto;
}
.container[data-theme="light"] {
  background-image: linear-gradient(to right, transparent 1px, transparent 1px),
    linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px);
}
.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5504d9;
}

.row_center {
  display: flex;
  align-items: center;
  font-size: 20px !important;
}
.table_titles_wrapper {
  display: flex;
  padding: 0 16px;
  margin-top: 42px;
  gap: 128px;
}
.table_titles {
  width: 214px;
  min-width: 214px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.items {
  width: 214px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: var(--bg-color-primary-dark);
}
.items[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.items[data-none="true"] {
  display: none;
}
.reco {
  border: 1px solid #00ad92;
}
.reco[data-theme="light"] {
  border: 1px solid #00ad92;
}
.content_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.content_title {
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.content_content {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.content_content[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.table_wrapper {
  min-width: 1368px;
}
