.container {
  width: 100%;
  max-width: 1368px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 28px;
}
.item {
  margin: 8px;
}
