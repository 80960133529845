.container {
  margin-top: 8px;
}
.range_wrapper {
  padding: 0 30px;
}
.carriers_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
}
.charts_wrapper {
  padding: 22px 22px 0 22px;
}
.chart_title {
  width: 150px;
  height: 11px;
  margin: 0 0 10px 0px;
  font-family: var(--font-family-light);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
