/* styles.module.css */

.tableContainer {
  width: 100%;
  overflow-x: auto;
  position: relative;
}
.progress {
  position: absolute;
  left: 50%;
  top: 50%;
}
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px; /* Satırlar arasında boşluk */
}
.tableHeaderCellSelect,
.tableBodyCellSelect {
  display: flex;
  align-items: center;
  width: 64px;
  padding: 0 16px;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.tableHeaderRow {
  display: flex;
  background-color: var(--table-header-bg-color); /* Satır arka plan rengi */
  font-size: 12px;
  color: var(--text-color-secondary, #fff); /* Yazı rengi, varsayılan beyaz */
  padding: 0; /* İç boşluk kaldırıldı */
  height: 32px; /* Yükseklik ayarlandı */
  align-items: center; /* İçerik ortalandı */
  border-radius: 8px; /* Satır köşe yuvarlama */
}

.tableBodyRow {
  display: flex;
  background-color: var(--table-body-row-bg-color); /* Satır arka plan rengi */
  color: var(--text-color-primary, #000); /* Yazı rengi, varsayılan siyah */
  padding: 0; /* İç boşluk kaldırıldı */
  height: 48px; /* Yükseklik ayarlandı */
  align-items: center; /* İçerik ortalandı */
  border-radius: 8px; /* Satır köşe yuvarlama */
  margin-bottom: 4px; /* Satırlar arası boşluk */
}
.tableBodyRow[data-nth-child="true"]:nth-child(odd) {
  background-color: rgba(85, 4, 217, 0.1);
}
.tableBodyRow:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.tableBodyCell {
  flex: 1;
  padding: 0 16px; /* İç boşluk sadece yatayda */
  text-align: left;
  height: 48px; /* Yükseklik ayarlandı */
  display: flex;
  align-items: center; /* İçerik ortalandı */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Taşma üç nokta ile gösterilecek */
}
.tableBodyCellSpan {
  text-align: left;
  height: 48px; /* Yükseklik ayarlandı */
  display: flex;
  align-items: center; /* İçerik ortalandı */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Taşma üç nokta ile gösterilecek */
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.paginationSpan {
  margin-right: 16px;
  color: var(--text-color-primary, #000); /* Yazı rengi, varsayılan siyah */
}

.paginationButton {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--text-color-primary, #fff); /* Yazı rengi, varsayılan beyaz */
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  cursor: pointer;
}

.paginationButtonDisabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-color-disabled);
  cursor: not-allowed;
}

.paginationActive {
  background-color: rgb(236, 66, 82);
  color: #fff;
  font-weight: bold;
}

.pageSizeSelect {
  margin-right: 16px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-color-primary, #fff); /* Yazı rengi, varsayılan beyaz */
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  cursor: pointer;
}
