.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carriers_wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
}
.carriers_icon_wrapper img {
  width: 24px;
  height: 24px;
  opacity: 0.3;
}
.carriers_scroll {
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.get_item {
  display: flex;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 8px 0 4px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 1px solid;
  position: relative;
}
.chart_wrapper {
  margin-top: 8px;
}
.chart {
  padding: 18px 22px;
}
.chart_title {
  height: 11px;
  margin: 0 0 0 10px;
  font-family: var(--font-family-light);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chart_x_title {
  margin: 0;
  
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
