.market_content {
  width: 100%;
  height: 272px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
}
.market_content div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.market_content span {
  min-width: 440px;
  padding: 12px 28px 12px 12px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.market_content[data-theme="light"] span {
  background-color: var(--bg-color-primary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
