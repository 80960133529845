.container {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.world_image {
  position: absolute;
  left: 200px;
  top: -300px;
}
.world_image img {
  width: 900px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
.selected_wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
  border-bottom: solid 1px var(--border-color);
  padding: 12px 8px;
}
.selected_item {
  cursor: pointer;
  color: var(--text-color-secondary);
}
.selected_item[data-active="true"] {
  color: var(--text-color-primary);
}
.selected {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_title {
  width: 173px;
  height: 24px;
  margin: 20px 12px;
  font-family: Fabriga;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.settings_menu_wrapper {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-right: 12px;
}
.card_body {
  margin-top: 1em;
  display: flex;
  gap: 8px;
}
.main_watched_card_body {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item_card_wrapper {
  width: 100%;
}
.item_card_body {
  width: 100%;
  height: 458px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
  padding: 1em;
}
.item_card_header {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);

  font-family: Fabriga;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.item_card_chart {
  width: 100%;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.chart {
  --p: 100;
  --b: 18px;
  --c: #360d8b;
  --w: 200px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}
.chart:before,
.chart:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.chart:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  /* background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0); */
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.chart_in {
  --p: 18;
  --b: 18px;
  --c: #5216ca;
  --w: 200px;

  width: var(--w);
  aspect-ratio: 1;
  position: absolute;
  left: -4px;
  top: -5px;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}
.chart_in:before,
.chart_in:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.chart_in:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.chart_in_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chart_in_value {
  display: flex;
}
.chart_value {
  font-family: Fabriga;
  font-size: 64px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.chart_value_icon {
  font-family: Fabriga;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.chart_in_value p {
  margin: 0;
}
.chart_arrow_icon {
  position: relative;
  top: -18px;
}
.card_kpi {
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  margin-top: 12px;
}
.kpi_icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 12px;
}
.kpi_value {
  font-family: Fabriga;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-secondary);
  margin-left: 12px;
}
.kpi_value span {
  color: var(--text-color-primary);
  margin-right: 12px;
}
.tree_card {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 3em;
  overflow-x: auto;
}
.alert_review_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 104px;
}
.alert_review_body h1 {
  font-family: Fabriga;
  font-size: 64px;
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.table_wrapper {
  margin-top: 1em;
}
.long_text {
  width: 100px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
