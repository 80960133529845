.container {
  width: 440px;
  height: 512px;
  padding: 20px 10px 10px;
  border-radius: 18.9px;
  backdrop-filter: blur(5px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  background-color: rgba(255, 255, 255, 0.03);
}
.container[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.title_and_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 20px;
}
.card_title {
  width: 314px;
  height: 31px;

  font-size: 25.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}

.top_mask {
  width: 420px;
  height: 141px;
  margin-top: 30px;
  padding: 30px 15px 29px 20px;
  border-radius: 12.6px;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
}
.top_mask[data-theme="light"] {
  background-color: var(--bg-color-secondary-light);
}
.mask_title_wrapper {
  display: flex;
}
.mask_title {
  width: 198px;
  height: 25px;

  font-size: 20.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.mask_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.mask_ratio_wrapper {
  width: 196px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ratio_text {
  height: 21px;

  font-size: 17.6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.ratio_img_green {
  margin-left: 4px;
  position: relative;
  top: -3px;
}
.ratio_img_red {
  margin-left: 4px;
  position: relative;
  top: -2px;
}
.mask_content {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
}
.price {
  width: 92px;
  height: 39px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.price[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.bottom_mask {
  width: 419px;
  height: 240px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0 1px;
  padding: 25px 10px 0px 20px;
  border-radius: 6.3px;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
}
.bottom_mask[data-theme="light"] {
  background-color: var(--bg-color-secondary-light);
}
.bottom_mask_content {
  width: 129px;
  display: flex;
  flex-direction: column;
}
.bottom_mask_title {
  height: 17px;
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.bottom_mask_title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.bottom_mask_title[data-active="true"] {
  color: #ec4252;
}
.bottom_mask_title[data-active="false"] {
  color: #00ad92;
}
.bottom_mask_price {
  width: 112px;
  margin: 16px 0 8px 0;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.bottom_mask_price[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.bottom_mask_ratio_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bottom_mask_ratio_text {
  width: 112px;
  height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15.1px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}

.bottom_mask_ratio_text[data-active="true"] {
  color: #ec4252;
}
.bottom_mask_ratio_text[data-active="false"] {
  color: #00ad92;
}
.bottom_mask_ratio_img_green {
  margin-left: 4px;
  position: relative;
  top: -3px;
}
.bottom_mask_ratio_img_red {
  margin-left: 4px;
  position: relative;
  top: -1px;
}

@media only screen and (max-width: 640px) {
  .container {
    max-width: 320px;
    height: auto;
  }
  .top_mask,
  .bottom_mask {
    max-width: 100%;
    height: auto;
    gap: 8px;
  }
  .mask_title,
  .ratio_text,
  .price {
    font-size: 1rem;
  }
  .bottom_mask_title,
  .bottom_mask_ratio_text {
    font-size: 13px;
  }
  .bottom_mask_price {
    font-size: 14px;
  }
}

/* Mini KPI Css */
.mini_kpi_container {
  width: 349px;
  height: 208px;
  padding: 16px 0 0;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px) saturate(130%);
  -webkit-backdrop-filter: blur(5px) saturate(130%);
  background-color: rgba(255, 255, 255, 0.03);
  border-style: solid;
  border-width: 1px;
  border-image: conic-gradient(transparent, transparent) 1;
}
.mini_kpi_container[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 2px 0 rgba(108, 73, 172, 0.02),
    0 6px 5px 0 rgba(108, 73, 172, 0.03), 0 12px 10px 0 rgba(108, 73, 172, 0.04),
    0 22px 18px 0 rgba(108, 73, 172, 0.04);
}
.mini_kpi_container:hover {
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
}
.mini_kpi_container .card_title_and_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 24px;
}
.mini_kpi_container .title {
  width: 249px;
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.mini_kpi_container .slider_wrapper {
  width: 349px;
  height: 144px;
  padding-left: 8px;
  overflow: hidden;
  position: relative;
  left: -1px;
}
.mini_kpi_container .slider {
  min-width: 1015px;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: 700ms ease-in-out;
  -moz-transition: 700ms ease-in-out; /* Firefox 4 */
  -webkit-transition: 700ms ease-in-out; /* Safari and Chrome */
  -o-transition: 700ms ease-in-out; /* Opera */
  -ms-transition: 700ms ease-in-out; /* Explorer 10 */
}
.mini_kpi_container .metrics_card {
  width: 333px;
  min-width: 333px;
  height: 112px;
  margin: 24px 0 0;
  padding: 24px 12px 24px 16px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(0, 0, 7, 0.45);
}
.mini_kpi_container[data-theme="light"] .metrics_card {
  background-color: var(--bg-color-white);
  box-shadow: 0 4px 8px 0 rgba(108, 73, 172, 0.2),
    0 -25px 50px 0 rgba(108, 73, 172, 0.03);
}
.mini_kpi_container .metrics_title_and_ratio {
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.mini_kpi_container[data-theme="light"] .metrics_title_and_ratio {
  color: var(--text-color-secondary-light);
}
.mini_kpi_container .ratio_and_icon_wrapper {
  display: flex;
  align-items: center;
}
.mini_kpi_container .raito {
  width: 120px;
  height: 16px;
  font-family: var(--font-family-light);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}
.mini_kpi_container .ratio_icon_green {
  margin-left: 4px;
  position: relative;
  top: -3px;
}
.mini_kpi_container .ratio_icon_red {
  margin-left: 4px;
  position: relative;
  top: -2px;
}
.mini_kpi_container .price_and_chart_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.mini_kpi_container .price {
  height: 30px;
  font-family: var(--font-family-medium);
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.mini_kpi_container[data-theme="light"] .price {
  color: var(--text-color-primary-light);
}
.mini_kpi_container .chart_wrapper {
  width: 186px;
}
.mini_kpi_container .metrics_card_content {
  display: flex;
}
.mini_kpi_container .bottom_mask_content {
  width: 111px;
  display: flex;
  flex-direction: column;
}
.mini_kpi_container .bottom_mask_title {
  width: 100px;
  height: 14px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.mini_kpi_container[data-theme="light"] .bottom_mask_title {
  color: var(--text-color-secondary-light);
}
.mini_kpi_container .bottom_mask_current_pct {
  width: 100px;
  height: 14px;
  margin-top: 4px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.mini_kpi_container[data-theme="light"] .bottom_mask_current_pct {
  color: var(--text-color-secondary-light);
}
.mini_kpi_container .bottom_mask_current_pct[data-active="true"] {
  color: #ec4252;
}
.mini_kpi_container .bottom_mask_current_pct[data-active="false"] {
  color: #00ad92;
}
.mini_kpi_container .bottom_mask_price {
  width: 69px;
  height: 25px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.mini_kpi_container[data-theme="light"] .bottom_mask_price {
  color: var(--text-color-primary-light);
}
.mini_kpi_container .bottom_mask_ratio_wrapper {
  display: flex;
  align-items: center;
}
.mini_kpi_container .bottom_mask_ratio_text {
  height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.mini_kpi_container[data-theme="light"] .bottom_mask_ratio_text {
  color: var(--text-color-secondary-light);
}
.mini_kpi_container .bottom_mask_ratio_img_green {
  margin-left: 2px;
  position: relative;
  top: -3px;
}
.mini_kpi_container .bottom_mask_ratio_img_red {
  margin-left: 2px;
  position: relative;
  top: -1px;
}

.m_container .slider_buttons {
  width: 349px;
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  transition: ease-in-out 300ms;
}
.m_container:hover .slider_buttons {
  display: flex;
}
.active_wrapper {
  margin: 0 10px;
  display: flex;
}
.active_slide {
  width: 18px;
  height: 4px;
  display: block;
  margin: 0 4px;
  border-radius: 1px;
  border: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: ease-in-out 1s;
}
.active_slide[data-theme="light"] {
  background-color: rgba(0, 0, 0, 0.2);
}
.active_slide[data-active="true"] {
  background-color: #ec4252;
}
.prev_arrow {
  background-color: var(--color-background-secondary);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
}
.prev_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_left {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.arrow_left[data-theme="light"] {
  border: solid var(--text-color-primary-light);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.next_arrow {
  background-color: var(--color-background-secondary);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 50% 50% 0;
}
.next_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_right {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}
.arrow_right[data-theme="light"] {
  border: solid var(--text-color-primary-light);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}
