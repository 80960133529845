.menu_wrapper {
    width: 100%;
    height: 100vh;
    padding: 12px;
    overflow-y: auto;
  }
  .menu_title {
    font-size: 18px;
    text-align: center;
    color: var(--text-color-primary);
  }