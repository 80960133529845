.tableHeaderCell {
  display: flex;
  align-items: center; /* İçerik ortalandı */
  gap: 8px;
  height: 32px; /* Yükseklik ayarlandı */
  position: relative;
  flex: 1;
  padding: 0 16px; /* İç boşluk sadece yatayda */
  text-align: left;
  font-weight: bold;
  text-transform: uppercase; /* Başlık metin büyük harf */
  cursor: grab;
}
.tableHeaderCell:active {
  cursor: grabbing;
}

.tableHeaderCell:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Üzerine gelindiğinde arka plan rengi değişir */
}
/* Sıralama ikonları için stil */
.headerCellIcons {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; /* İkon boyutu */
  color: var(--text-color-primary, #fff); /* İkon rengi, varsayılan beyaz */
  cursor: pointer;
}
