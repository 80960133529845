.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 20px 0 28px 16px;
}
.trends_and_detail_button_wrapper {
  display: flex;
  align-items: center;
}
.holidays_text {
  width: 61px;
  height: 17px;
  margin-right: 8px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.holidays_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}

.trends_and_detail_button {
  width: 190px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.trends_and_detail_button[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.button_text {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-family: var(--font-family-medium);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
}
.button_text[data-active="true"] {
  width: 93px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: rgba(255, 255, 255, 0.1);
}
.button_text[data-active="true"][data-theme="light"] {
  background-color: var(--bg-color-white);
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.carriers_wrapper {
  flex-grow: 1;
}
.setting_icon {
  width: 48px;
  height: 48px;
  margin-left: 4px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.open_menu_wrapper {
  position: relative;
  z-index: 10;
}
.open_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  margin-top: 8px;
  width: 232px;
  height: 392px;
  padding: 8px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_card {
  width: 216px;
  height: 120px;
  padding: 20px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.24);
}
.open_menu_card[data-theme="light"] {
  background-color: var(--bg-color-primary-light);
}
.open_menu_title_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.title_text {
  width: 120px;
  height: 24px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.title_text[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.open_menu_icons {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.open_menu_icons[data-theme="light"] {
  filter: invert(81%) sepia(32%) saturate(1557%) hue-rotate(206deg)
    brightness(88%) contrast(88%);
}
.open_menu_buttons_wrapper {
  display: flex;
  justify-content: space-between;
}
.open_menu_buttons[data-active="true"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-family: var(--font-family-medium);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.open_menu_buttons[data-active="true"][data-theme="light"] {
  background-color: var(--button-bg-color-active-primary-light);
  color: var(--text-color-secondary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.open_menu_buttons[data-active="false"] {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-family-medium);

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.open_menu_buttons[data-active="false"][data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
  color: var(--text-color-secondary-light);
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.chart_wrapper {
  padding: 22px 22px 0 22px;
}
.chart_title {
  width: 150px;
  height: 11px;
  margin: 0 0 10px 10px;
  font-family: var(--font-family-light);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.range_wrapper {
  padding: 0 30px;
  margin-top: 20px;
}
.trends_wrapper[data-active="false"] {
  position: absolute;
  visibility: hidden;
}
.detail_wrapper[data-active="false"] {
  visibility: hidden;
  position: absolute;
}

.table_th {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}

.name {
  width: 214px;
  margin-left: 35px;
}
.start_date {
  width: 82px;
  margin-left: 28px;
}
.end_date {
  width: 82px;
  margin-left: 28px;
}
.country {
  width: 145px;
  margin-left: 28px;
}
.type {
  width: 102px;
  margin-left: 28px;
}
.length {
  width: 5px;
  margin-left: 28px;
}
.mov_date {
  width: 70px;
  margin-left: 57px;
}
.mov_loc {
  margin-left: 21px;
}
.table_body {
  height: 389px;
  overflow-y: scroll;
  margin-top: 4px;
}

.table_td {
  width: 1064px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 4px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}
.td_checkbox {
  margin-left: 5px;
  width: 16px;
}
.td_name {
  width: 214px;
  margin-left: 14px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_start_date {
  width: 82px;
  margin-left: 28px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_end_date {
  width: 82px;
  margin-left: 28px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_country {
  width: 145px;
  margin-left: 28px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_type {
  width: 102px;
  margin-left: 28px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_length {
  width: 5px;
  margin-left: 28px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_mov_date {
  width: 24px;
  margin-left: 57px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.td_mov_loc {
  width: 19px;
  margin-left: 67px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
