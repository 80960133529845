.container {
  display: flex;
  gap: 8px;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_title {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0 28px 16px;
}
.card_title[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.card_header_select_options {
  width: 90px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 4px 4px 4px 20px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  background-color: #4a4266;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.card_header_select_options[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_select_options span {
  font-family: var(--font-family-medium);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.card_header_select_options[data-theme="light"] span {
  color: var(--text-color-primary-light);
}
.open_menu {
  width: 90px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 #0b001c;
  background-color: #383051;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
}
.open_menu[data-theme="light"] {
  background-color: var(--bg-color-white);
}
.open_menu[data-active="false"] {
  display: none;
}
.open_menu_item {
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
}
.open_menu_item[data-theme="light"] {
  color: var(--text-color-primary-light);
}
.open_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.open_menu_item[data-theme="light"]:hover {
  background-color: var(--button-bg-color-primary-light);
}
.card_header_range_and_select_options .icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(-360deg);
}
