.card_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 16px;
}
.slider_title {
  height: 24px;
  margin: 0 16px;
  
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.slider {
  display: flex;
}
.prev_arrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.1);
}
.prev_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_left {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.arrow_left[data-theme="light"] {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  right: -2px;
}
.next_arrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 50% 50% 0;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(255, 255, 255, 0.1);
}
.next_arrow[data-theme="light"] {
  background-color: var(--button-bg-color-primary-light);
}
.arrow_right {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}
.arrow_right[data-theme="light"] {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
}

.active_wrapper {
  display: flex;
  justify-content: center;
}
.active_slide {
  width: 18px;
  height: 4px;
  display: block;
  margin: 0 4px;
  border-radius: 1px;
  border: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: ease-in-out 1s;
}
.active_slide[data-active="true"] {
  background-color: #ec4252;
}

.custom_table_row {
  width: 32px;
  height: 8px;
  display: flex;
  align-items: center;
  border-radius: 4.5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.row {
  height: 8px;
  display: inline-block;
  border-radius: 4.5px;
  background-color: #9c00ed;
}
.row[data-active="blue"] {
  background-color: #144aff;
}
