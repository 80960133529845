.container {
  display: flex;
  align-items: center;
  height: 56px;
}
.icon_wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 12px;
  margin-left: 8px;
}
.icon_wrapper img {
  width: 24px;
  height: 24px;
}
.icon {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  margin-left: 8px;
}
.title {
  width: 82px;
  height: 14px;
  font-family: var(--font-family-light);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}
.title[data-theme="light"] {
  color: var(--text-color-secondary-light);
}
.content {
  display: flex;
}
.total {
  width: 120px;
  height: 24px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.total[data-theme="light"] {
  color: var(--text-color-primary-light);
}
