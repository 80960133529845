.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  position: relative;
  z-index: 5;
  gap: 8px;
}
.select_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  border-bottom: solid 1px var(--border-color);
  padding-bottom: 1em;
  position: relative;
}
.select_wrapper[data-active="false"] {
  display: none;
}
.select_button {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  color: var(--text-color-secondary);
}
.select_button[data-active="true"] {
  color: var(--text-color-primary);
}

.active_button {
  width: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  border: 2px solid #ec4252;
  background-color: #ec4252;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  left: 0;
  transition: ease-in-out 0.2s;
}
.title {
  font-family: Fabriga;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color-primary);
}
.body {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}
.body_content {
  flex: 1 1 auto;
}
.chart_wrapper {
  display: flex;
  gap: 8px;
}

.card_header {
  display: flex;
  margin: 20px 16px;
  align-items: center;
  justify-content: space-between;
}
.card_title {
  font-size: 20px;
}
.select_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  border-bottom: solid 1px var(--border-color);
  padding-bottom: 1em;
  position: relative;
}
.demand_dropdowns_wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 1em;
}
.row {
  display: flex;
}
.row .th,
.row .td {
  min-width: 80px;
  font-size: 11px;
}
.row .th {
  color: var(--text-color-secondary);
}
.row .td .button {
  width: 64px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  font-size: 9px;
}
.chart_th_wrapper {
  width: 100%;
}
.chart_th {
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 0 8px;
}
.chart_td_wrapper {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
}
.chart_th_item,
.chart_td_item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 11px;
  font-family: var(--font-family-light);
  color: var(--text-color-secondary);
}

.chart_td_item {
  padding: 8px;
  color: var(--text-color-primary);
}
.demad_table_head {
  min-height: 1024px;
}
